import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'
import { min, max } from '../utils/breakpoints'

const Locations = ({ locations, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({ align: 'center' })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const onSelect = useCallback(() => {
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
  }, [embla, onSelect])

  return (
    <div
      css={[
        css`
          ${tw`relative w-full overflow-hidden`}
          &.is-draggable {
            ${tw`cursor-grab`}
          }
          &.is-dragging {
            ${tw`cursor-grabbing`}
          }
        `,
        style,
      ]}
      ref={viewportRef}
    >
      <div css={tw`flex flex-row w-full`}>
        {locations.map((office, i) => (
          <div
            key={i}
            css={[
              tw`mx-3 filter grayscale w-3/4 flex-none flex flex-col lg:(mx-8 w-3/4)`,
              selectedIndex === i && tw`grayscale-0`,
            ]}
          >
            <div css={tw`relative flex-none w-full`}>
              <div
                css={css`
                  ${tw`w-full`}
                  ${max.lg} {
                    padding-top: 100%;
                  }
                  ${min.lg} {
                    padding-bottom: 62%;
                  }
                `}
              >
                <Image
                  image={office.image}
                  imageMobile={office.mobileImage}
                  style={tw`absolute inset-0`}
                />
              </div>
              <div
                css={css`
                  ${tw`absolute inset-0`}
                  background: linear-gradient(
                    0deg,
                    rgba(23, 15, 79, 1) 10%,
                    rgba(0, 0, 0, 0) 100%
                  );
                  {min.lg} {
                    background: linear-gradient(
                      90deg,
                      rgba(23, 15, 79, 0.9) 24.02%,
                      rgba(0, 0, 0, 0) 100%
                    );
                  }
                `}
              />
              <div css={tw`absolute bottom-0 left-0 px-6 py-2 w-full lg:(p-12 w-2/3) xl:(p-20)`}>
                <Heading content={office.title} headingType="h1" style={tw`font-bold text-white`} />
                <Text
                  content={office.descriptionNode}
                  style={tw`hidden mt-6 text-white lg:block`}
                />
              </div>
            </div>
            <div css={tw`flex-1 px-6 py-12 bg-primary-500 lg:hidden`}>
              <Text content={office.descriptionNode} style={tw`text-white`} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Locations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default Locations
